<template>
  <el-card>
    <el-row :gutter="20" class="header">
        <el-col :span="7">
          <el-input placeholder="请输入用户姓名..." clearable v-model="queryForm.query"></el-input>
        </el-col>
        <el-button type="primary" :icon="Search" @click="initUserList">搜索</el-button>
    </el-row>
    <el-table :data="tableData" stripe style="width: 100%">
<!--      <el-table-column prop="id" label="#ID" width="80" />-->

<!--      <el-table-column prop="nickName" label="用户昵称" width="200" />-->
<!--      <el-table-column  prop="avatarUrl" label="头像" width="200">-->
<!--        <template v-slot="scope">-->
<!--          <img :src="scope.row.avatarUrl" width="50" height="50"/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="doctorId" label="编号" />
      <el-table-column prop="doctorName" label="姓名" />
      <el-table-column prop="doctorPhone" label="手机号" />
      <el-table-column prop="departmentName" label="科室" />
<!--      <el-table-column prop="remark" label="商品大类描述"/>-->
      <el-table-column prop="action" label="操作" width="300">
        <template v-slot="scope">
          <el-button type="success" @click="handleChangeImage(scope.row)">更换图片</el-button>
          <el-button type="primary" :icon="Edit" @click="handleDialogValue(scope.row)">编辑</el-button>
          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>

      </el-table-column>
    </el-table>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

  </el-card>

</template>

<script setup>
import {Search } from '@element-plus/icons-vue'
import { ref } from 'vue'
import  axios from '@/util/axios'







const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10
})

const total=ref(0)

const tableData=ref([])



const initUserList=async()=>{
  const res=await axios.post("zy-user/list",queryForm.value);
  console.log(res)
  tableData.value=res.data.data.map.zyUserList;
  total.value=res.data.data.map.total;
}

initUserList();

const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initUserList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initUserList();
}

</script>

<style lang="scss" scoped>

.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}

</style>